
import { ScrollMore } from '@blax-modules/base-front-end/resources/js/ui/scroll-more';
import { FullscreenSlider } from '@blax-modules/base-front-end/resources/js/sliders/fullscreen-slider';
import { Modal } from "@blax-modules/base-front-end/resources/js/ui/modal";
import { waitLoad } from "@blax-modules/base-front-end/resources/js/utilities/load";


const DEFAULT_PARAMS = {
    
}
export class Home {
    constructor() {
        this.init();
    
        waitLoad().then(()=>{
            this.closePopup();
        })
    }
    
    init() {
        let homeEl = document.querySelector('.homemain');
        if(!homeEl)
            return;
        new ScrollMore();
        new FullscreenSlider({
            container: '.home-slider'
        })
    }
    
    closePopup() {
        let popups = document.querySelectorAll('.wrapper_lightbox_confirm');
        popups.forEach((el)=>{
            let closeButton = el.querySelector('.x_close')
            closeButton.addEventListener('click', ()=>{
                el.classList.add('closed')
            })
            let overlay = el.querySelector('.overlay__lightbox');
            overlay.addEventListener('click', (e)=>{
                if(e.currentTarget === e.target) {
                    el.classList.add('closed')
                }
            })
        })
    }
}