import { updateImage } from '../pictures/cover';

const DEFAULT = {
    basePath: '',
    useHistory: true,
    modalSelector: '[data-modal]',
    modalAttr: 'data-modal',
    modalButtonSelector: '[data-open-modal]',
    buttonAttr: 'data-open-modal',
    classOpen: 'modal--open',
    classOpening: 'modal--opening',
    classClosing: 'modal--closing',
    classBodyOpen: 'modal--locked',
    classBodyOpening: 'modal--locking',
    classBodyClosing: 'modal--unlocking',
    prevSelector: '.modal--prev',
    nextSelector: '.modal--next',
    closeSelector: '.modal--close',
    urlRegex: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9]{1,6}\b\/[a-zA-Z0-9]*\/[a-zA-Z0-9]*\/([a-zA-Z0-9]*)/
}

const _searchElements = function () {
    this.modals = document.querySelectorAll(this.params.modalSelector);
    this.buttons = document.querySelectorAll(this.params.modalButtonSelector);

    this.prevButtons = document.querySelectorAll(this.params.prevSelector);
    this.nextButtons = document.querySelectorAll(this.params.nextSelector);
    this.closeButtons = document.querySelectorAll(this.params.closeSelector);
}



const _bindEvents = function () {
    this.buttons.forEach(el => {
        el.addEventListener('click', (e) => {
            e.preventDefault();
            _onClick.call(this, el)
        });
    });
    this.prevButtons.forEach(el => {
        el.addEventListener('click', () => {
            this.prev();
        });
    });
    this.nextButtons.forEach(el => {
        el.addEventListener('click', () => {
            this.next()
        });
    });
    this.closeButtons.forEach(el => {
        el.addEventListener('click', () => {
            this.close();
        });
    });
    this.modals.forEach(el => {
        el.addEventListener('click', (e) => {
            if (e.target === e.currentTarget)
                this.close();
        });
    });
    window.onkeydown = (event) => {
        event = event || window.event;
        if (event.keyCode === 27) {
            this.close();
        } else if (event.keyCode === 37) {
            this.prev();
        } else if (event.keyCode === 39) {
            this.next();
        }
    }
    window.addEventListener('popstate', (a) => {
        if (!this.params.useHistory)
            return;
        if (a.state && a.state.code)
            this.open(a.state.code)
        else
            this.close();
    });
}

const _openEl = function (element, open, opening, closing) {
    if (element.matches('.' + closing)) {
        let event = document.createEvent("HTMLEvents");
        event.initEvent("transitionend", true, true);
        event.eventName = "transitionend";
        element.dispatchEvent(event);
    }
    else if (element.matches('.' + open))
        return;

    element.classList.add(open)
    element.classList.add(opening)
    requestAnimationFrame(() => {
        requestAnimationFrame(() => {
            element.querySelectorAll('img.cover').forEach((el) => updateImage(el));
            element.classList.remove(opening)
        })
    })
    let monitor = setInterval(function () {
        let element = document.activeElement;
        if (element && element.tagName == 'IFRAME') {
            clearInterval(monitor);
            parent.focus();
        }
    }, 100);
}

const _closeEl = function (element, open, closing) {
    if (!element.matches('.' + open) || element.matches('.' + closing))
        return;
    let onEnd = () => {
        element.classList.remove(closing);
        element.classList.remove(open);
        element.removeEventListener("transitionend", onEnd);
    }
    element.addEventListener("transitionend", onEnd)
    element.classList.add(closing);
}

const _openModal = function (modal) {
    _openEl.call(this, modal, this.params.classOpen, this.params.classOpening, this.params.classClosing)
}

const _closeModal = function (modal) {
    _closeEl.call(this, modal, this.params.classOpen, this.params.classClosing)
    //kill videos
    modal.querySelectorAll('.playing').forEach(el => {
        let video = el.querySelector('iframe')
        video.parentNode.removeChild(video);
        el.classList.remove('playing')
    })
}

const _openBody = function () {
    _openEl.call(this, document.body, this.params.classBodyOpen, this.params.classBodyOpening, this.params.classBodyClosing)
}

const _closeBody = function () {
    _closeEl.call(this, document.body, this.params.classBodyOpen, this.params.classBodyClosing)
}

const _onClick = function (button) {
    let buttonCode = button.getAttribute(this.params.buttonAttr);
    this.modals.forEach(modal => {
        let modalCode = modal.getAttribute(this.params.modalAttr);
        //open the modal
        if (buttonCode === modalCode) {
            this.currentSlug = buttonCode;
            if (!document.querySelector('.' + this.params.classOpen)) {

                _lockScroll.call(this)
            }
            if (!modal.matches(this.params.classOpen))
                _changeUrl.call(this, modalCode)
            _openModal.call(this, modal);
        }
        //close the modal
        else {
            _closeModal.call(this, modal)
        }
    });
}

const _lockScroll = function () {
    //if you want scroll top when modal is openning
    /*if ('scrollBehavior' in document.documentElement.style)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    else
        window.scrollTo(0, 0)*/
    _openBody.call(this);
}

const _unlockScroll = function () {
    _closeBody.call(this)
}

const _getSlugFromUrl = function () {
    let match = location.href.match(this.params.urlRegex);
    if (match)
        return location.href.match(this.params.urlRegex)[2]
    else
        return '';
}

const _openFromUrl = function () {
    let urlSlug = _getSlugFromUrl.call(this);

    if (urlSlug) {
        this.open(urlSlug)
    }
}

const _changeUrl = function (code) {
    if (code) {
        if (location.href !== this.params.basePath + '/' + code) {
            if (this.params.useHistory)
                history.pushState({ code: code }, '', this.params.basePath + '/' + code)
        }
    }
    else {
        if (location.href !== this.params.basePath) {
            if (this.params.useHistory)
                history.pushState({ code: '' }, '', this.params.basePath)
        }
    }
}

export class Modal {
    constructor(params = {}) {
        console.log(this.params)
        this.params = Object.assign({}, DEFAULT, params);

        this.currentSlug = '';

        if (document.readyState !== 'loading') {
            this.init();
        } else {
            document.addEventListener('DOMContentLoaded', () => {
                this.init();
            });
        }
    }

    init() {
        _searchElements.call(this)
        _bindEvents.call(this)

        this.modals.forEach(el => {
            if (el.matches('.' + this.params.classOpen)) {
                _lockScroll.call(this)
            }
        })

        _openFromUrl.call(this);
    }

    open(slug) {
        let button;

        if (slug === this.currentSlug)
            return;
        this.buttons.forEach(el => {
            let buttonSlug = el.getAttribute(this.params.buttonAttr);
            if (buttonSlug === slug) {
                button = el;
                _onClick.call(this, el)
            }
        })
    }

    prev() {
        let prev;
        for (let el of this.buttons) {
            let buttonSlug = el.getAttribute(this.params.buttonAttr);
            if (buttonSlug === this.currentSlug) {
                if (!prev) {
                    prev = this.buttons[this.buttons.length - 1];
                }
                _onClick.call(this, prev)
                return;
            }
            prev = el;
        }
    }

    next() {
        let hasNext = false;
        for (let el of this.buttons) {
            let buttonSlug = el.getAttribute(this.params.buttonAttr);
            if (hasNext) {
                hasNext = false;
                _onClick.call(this, el)
                return;
            }
            if (buttonSlug === this.currentSlug) {
                hasNext = true;
            }
        }
        if (hasNext) {
            let el = this.buttons[0];
            _onClick.call(this, el)
        }
    }

    close() {
        if (document.querySelector('.' + this.params.classOpen)) {
            _unlockScroll.call(this)
            _changeUrl.call(this, '')
        }
        this.currentSlug = '';
        this.modals.forEach(modal => {
            _closeModal.call(this, modal);
        })
    }
}