require("@blax-modules/base-front-end/resources/js/");
require("@blax-modules/blocks/resources/js/");
require("@blax-modules/calltoactions/resources/js/");
require("@blax-modules/careers/resources/js/");
require("@blax-modules/contact/resources/js/");
require("@blax-modules/contents/resources/js/");
require("@blax-modules/faqs/resources/js/");
require("@blax-modules/gallery_items/resources/js/");
require("@blax-modules/general_templates/resources/js/");
require("@blax-modules/navigation/resources/js/");
require("@blax-modules/newsletter/resources/js/");
require("@blax-modules/partners/resources/js/");
require("@blax-modules/popups/resources/js/");
require("@blax-modules/projects/resources/js/");
require("@blax-modules/promotions/resources/js/");
require("@blax-modules/services/resources/js/");
require("@blax-modules/social_networks/resources/js/");
require("@blax-modules/video_ctas/resources/js/");
require("@blax-modules/videos/resources/js/");
