import { waitLoad } from '../utilities/load'

const defaultParams = {
    selector: '.go-top',
    threshold: 20
}

export default class BackToTop {
    constructor(params = {}) {
        this.params = Object.assign({}, defaultParams, params);
        this.element = document.querySelector(this.params.selector);
        waitLoad().then(()=>{
            this.init();
        })
    }

    init() {
        if (!this.element) {
            return
        }
        document.onscroll = () => {
            this.onScroll();
        }
        this.element.addEventListener('click', () => {
            this.goTop();
        })
    }

    onScroll() {
        if (document.body.scrollTop > this.params.threshold || document.documentElement.scrollTop > this.params.threshold) {
            this.element.style.display = "flex";
        } else {
            this.element.style.display = "none";
        }
    }

    goTop() {
        const scrolledDistance = document.documentElement.scrollTop || document.body.scrollTop;
        if (scrolledDistance > 0) {
            window.requestAnimationFrame(() => {
                this.goTop();
            });
            window.scrollTo(0, scrolledDistance - scrolledDistance / 8);
        }

    }
};
