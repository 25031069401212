

import { waitLoad } from "@blax-modules/base-front-end/resources/js/utilities/load";

const jsonFeed = [
    {
        'caption': "",
        'media_url': "https://instagram.fymq2-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s640x640/79978384_469126393744088_7916138614893130979_n.jpg?_nc_ht=instagram.fymq2-1.fna.fbcdn.net&_nc_cat=108&_nc_ohc=Q7QQgKklwnkAX_DSFaN&oh=9cc99d84327e17dfbd8e848650cba884&oe=5EEDEB1A",
        'permalink': "https://www.instagram.com/p/B6VoGVcJIXI/"
    },
    {
        'caption': "",
        'media_url': "https://instagram.fymq2-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s640x640/75156333_741732342986182_2264469113467299868_n.jpg?_nc_ht=instagram.fymq2-1.fna.fbcdn.net&_nc_cat=110&_nc_ohc=pMm9pPGGQmUAX8ojwNQ&oh=0266fae1a04477cc97299f25e94fc8fd&oe=5EED1C40",
        'permalink': "https://www.instagram.com/p/B6Vn45FJaCQ/"
    },
    {
        'caption': "",
        'media_url': "https://instagram.fymq2-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s640x640/73063063_149195452981072_8736031418741838934_n.jpg?_nc_ht=instagram.fymq2-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=vWATi1EtieUAX-4aHSm&oh=4401a2227aacecc06f2ed444840cdecf&oe=5EEC4C69",
        'permalink': "https://www.instagram.com/p/B3xPR85JeNm/"
    },
    {
        'caption': "",
        'media_url': "https://instagram.fymq2-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s640x640/67123092_2365759770413044_7654394680418903781_n.jpg?_nc_ht=instagram.fymq2-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=KDp5xcZwUBQAX85SGji&oh=27f18e47fea683e53937dc1962e76210&oe=5EEBD26E",
        'permalink': "https://www.instagram.com/p/B03UZjyg9r4/"
    },
    {
        'caption': "",
        'media_url': "https://instagram.fymq2-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s640x640/61115383_341104596471523_5956689439661701732_n.jpg?_nc_ht=instagram.fymq2-1.fna.fbcdn.net&_nc_cat=107&_nc_ohc=BoIqr0f1BcUAX_IWEm6&oh=7ebdc39a99bfe81c61cd1fde32f79624&oe=5EED8BF1",
        'permalink': "https://www.instagram.com/p/ByVLEWNgytK/"
    },
    {
        'caption': "",
        'media_url': "https://instagram.fymq2-1.fna.fbcdn.net/v/t51.2885-15/e35/61602237_745293382535358_7111779724287557621_n.jpg?_nc_ht=instagram.fymq2-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=iNXENHdwC5sAX87aS3e&oh=cba04c8475c3aa1bd2fa259432377364&oe=5EEF1C12",
        'permalink': "https://www.instagram.com/p/ByVKyOiAO8A/"
    }
]

const DEFAULT_PARAMS = {
    accessToken: 'IGQVJXd0pSbzFLZA0JuSzBTNlEyVkJNRFZAZAZAHUxQTZAMczVQdUhDRDNhd3RqRGE1NFhYZAVRqQTRuNGl3Szh1ZAFp4VDZA0SG5QM3BvcXFfYlFuVDI1bGxQSHVhbm9FYjFFT0ZACRGFrTTJITHhFcGZAYb3RVYgZDZD',
    feedContainerSelector: '.bl-instafeed',
    itemToshow: 6,
    url: 'https://graph.instagram.com/me/media?fields=',
    urlParams: ['caption', 'media_url', 'permalink']
};

export class InstagramFeed {
    constructor(params) {
        this.params = Object.assign({}, DEFAULT_PARAMS, params)
        waitLoad().then(() => {
            this.initInstagramFeed();
            this.getPublicFeed();
        })
    }

    initInstagramFeed() {
        this.feedContainer = document.querySelector(this.params.feedContainerSelector);
        if (!this.feedContainer)
            return;
        this.accessToken = '&access_token=' + this.params.accessToken;
        if (this.feedContainer.getAttribute('instragram-access-token'))
            this.accessToken = '&access_token=' + this.feedContainer.getAttribute('instragram-access-token');
        this.urlParams = this.params.urlParams;
        this.url = this.params.url
        this.itemToShow = this.params.itemToshow

        const itemNb = 6;

        let urlString = this.url + this.urlParams.join() + this.accessToken
        this.getFeed(urlString);
    }

    getFeed(urlString) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', urlString, true)
        xhr.send();

        xhr.onload = processRequest
        let container = this.feedContainer
        function processRequest(e) {
            if (xhr.readyState == 4 && xhr.status == 200) {
                container.innerHTML = ''
                var response = JSON.parse(xhr.responseText)
                let dataArr = Object.values(response.data)
                console.log(response.data)
                dataArr.slice(0, 6).forEach(element => {
                    container.innerHTML += `<div><a href="${element.permalink}" target="_blank" rel="noopener"><img src="${element.media_url}" alt="${element.caption}"></a></div>`
                });
            }
        }
    }

    getFeedStatic() {
        if (!this.feedContainer) return
        let container = this.feedContainer
        let dataArr = Object.values(jsonFeed)
        dataArr.slice(0, 6).forEach(element => {
            container.innerHTML += `<div><a href="${element.permalink}" target="_blank" rel="noopener"><img src="${element.media_url}" alt="${element.caption}"></a></div>`
        });
    }

    getPublicFeed() {
        if (!this.feedContainer) return
        var xhr = new XMLHttpRequest();
        xhr.open('GET', 'https://www.instagram.com/fortinautoradio/?__a=1', true)
        xhr.send();

        xhr.onload = processRequest
        let container = this.feedContainer
        function processRequest(e) {
            if (xhr.readyState == 4 && xhr.status == 200) {
                container.innerHTML = ''
                var response = JSON.parse(xhr.responseText)
                let dataArr = Object.values(response.graphql.user.edge_owner_to_timeline_media.edges)
                dataArr.slice(0, 6).forEach(element => {
                    container.innerHTML += `<div><a href="https://www.instagram.com/p/${element.node.shortcode}" target="_blank" rel="noopener"><img src="${element.node.thumbnail_src}" alt="${element.node.accessibility_caption}"></a></div>`
                });
            }
        }
    }

}