import {FormValidation} from "@blax-modules/base-front-end/resources/js/forms/form-validation";
import { waitLoad } from "@blax-modules/base-front-end/resources/js/utilities/load"

const MODALS_GOT_CLOSED_DELAY_IN_DAYS = 1;

export class NewsletterModal {
    constructor() {
        this.delayForClosing;
        this.hasNewsletterBeenSubmitted = true;
        this.modalHasBeenClosed = true;
        waitLoad().then(()=>{
            if(!document.querySelector("#frm_newsletter_pop-up"))
                return;
            new FormValidation("#frm_newsletter_pop-up");
            this.checkCookie('newsletterHasBeenSubmited');
            this.checkCookie('modalHasBeenClosed');
            this.addEscListener();
        })
    }

    addEscListener() {
        document.onkeydown = (evt) => {
            evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key === "Escape" || evt.key === "Esc");
            }
            //TODO: changer le fonctionnement pour mettre ça dans un fichier qui fait du sens
            if (isEscape) {
                const modalBtn = document.querySelector('.newsletter--pop-up')
                const menuBtn = document.querySelector('.hamburger-ctn')
                if(modalBtn.classList.contains('newsletter--pop-up--is-visible')) {
                    this.closeModal();
                } else if(menuBtn.classList.contains('opened')) {
                    this.closeMenu();
                }
            }
        };
    }

    closeMenu() {
        const menuBtn = document.querySelector('#open-menu-button')
        const menu = document.querySelector('.hamburger-ctn')
        menu.classList.toggle('opened')
        menuBtn.checked = !menuBtn.checked;
        document.body.classList.remove('menu-open');
    }

    setupNewsletterModal() {
        const form = document.querySelector('#frm_newsletter_pop-up');
        if(!form)
            return;
        const delayForOpening = form.getAttribute('data-pop-up-delay') * 1000;
        this.delayForClosing = form.getAttribute('data-pop-up-delay-close') * 1000;
    
        if(!document.querySelector('.modal--standalone')) {
            document.querySelector('.newsletter--pop-up').addEventListener('click', (e) => {
                this.toggleNewsletterModal(e);
            });
            window.setTimeout(() => {
                this.toggleNewsletterModal();
            }, delayForOpening);
        }
    }

    toggleNewsletterModal(e) {
        if (e && e.target.classList.contains('newsletter--pop-up') || e && e.target.classList.contains('newsletter-closeBtn')) {
            this.setCookie('modalHasBeenClosed', MODALS_GOT_CLOSED_DELAY_IN_DAYS);
            document.querySelector('.newsletter--pop-up').classList.toggle('newsletter--pop-up--is-visible');
        } else if (!e) {
            document.querySelector('.newsletter--pop-up').classList.toggle('newsletter--pop-up--is-visible');
        }
    
        window.setTimeout(() => {
            this.closeModal();
        }, this.delayForClosing);
    }

    closeModal() {
        if (document.querySelector('.newsletter--pop-up').classList.contains('newsletter--pop-up--is-visible')) {
            document.querySelector('.newsletter--pop-up').classList.remove('newsletter--pop-up--is-visible');
            this.setCookie('modalHasBeenClosed', MODALS_GOT_CLOSED_DELAY_IN_DAYS);
        }
    }

    setCookie(cname, cduration){
        const date = new Date();
        date.setTime(date.getTime() + (cduration * 24 * 60 * 60 * 1000));
        var expires = "expires=" + date.toUTCString();
        document.cookie = cname +"=" + true + ";" + expires + ";path=/";
    }

    getCookie(cname) {
        const name = cname + "=";
        const ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    checkCookie(cname) {
        var cookie = this.getCookie(cname);
        if (!cookie && cname === "newsletterHasBeenSubmited") {
            this.hasNewsletterBeenSubmitted = false;
        } else if(!cookie && cname === "modalHasBeenClosed"){
            this.modalHasBeenClosed = false;
        }
        if(!this.modalHasBeenClosed && !this.hasNewsletterBeenSubmitted){
            this.setupNewsletterModal();
        }
    }    
}