import { setCookie, storageAvailable, getCookie, cleanCookieName} from '../utilities/cookies'
import { waitLoad } from "../utilities/load";

var DEFAULT_OPTIONS = {
    occurence: 'eternity' //eternity, session, page
}

//changer popup pour message 
//mettre les variables dans le css

export class BindMessage {

    constructor(name, options) {
        options = $.extend({}, DEFAULT_OPTIONS, options);

        let popups = document.querySelectorAll('.' + name + '-message');
        for(let i in popups) {
            if(!popups.hasOwnProperty(i))
                continue;
            let popup = popups[i],
                popupTimeout,
                popupOnTop;

            popupTimeout = (popup.matches('[data-' + name + '-timeout]')) ? parseInt(popup.getAttribute('data-' + name + '-timeout')) : 4000;

            popupOnTop = popup.matches('[data-' + name + '-pos]');
            if (popupOnTop) {
                document.body.classList.add('will-have-' + name + '-message')
            }

            var togglePopup = function (val) {
                popup.classList.toggle('visible', val);
                if (popupOnTop) {
                    document.body.classList.toggle('has-' + name + '-message', val);
                }
            }

            console.log('bind load', name)
            waitLoad().then(() => {
                var wasAccepted;
                if (options.occurence === 'eternity' && storageAvailable('localStorage'))
                    wasAccepted = !!localStorage.getItem(name + '-accepted');
                else
                    wasAccepted = !!getCookie(cleanCookieName(name));
                console.log(wasAccepted, name)
                if (!wasAccepted) {
                    setTimeout(function () {
                        togglePopup(true);
                    })
                }
            })

            let closeBtns = document.querySelectorAll('.' + name + '-close');
            for(let i in closeBtns) {
                if(!closeBtns.hasOwnProperty(i))
                    continue;
                let closeBtn = closeBtns[i];
                closeBtn.addEventListener('click', function () {
                    switch (options.occurence) {
                        case 'page':
                            break;
                        case 'session':
                            setCookie(cleanCookieName(name), '1', 0);
                            break;
                        default:
                            if (storageAvailable('localStorage'))
                                localStorage.setItem(name + '-accepted', '1');
                            else
                                setCookie(cleanCookieName(name), '1', 10000)
                            break;

                    }

                    togglePopup(false);
                });
            }
        }
    }
}