import { isImageOK } from '../utilities/image-ok'
import { waitLoad } from '../utilities/load'

const defaultParams = {
    selector: '.preloader',
    interval: 100,
    timeoutBeforeDone: 500,
    loadingClass: 'loading',
    loadedClass: 'loaded',
    errorClass: 'error',
    doneClass: 'done'
}

export class Preloader {
    constructor(params = {}) {
        this.params = Object.assign({}, defaultParams, params);

        waitLoad().then(()=>{
            setTimeout(()=> {
                this.init();
            }, 0)
        });
    }

    init() {
        this.preloaderEls = document.querySelectorAll(this.params.selector);
        this.blocks = [];

        this.setImgs();
        this.setGlobal();
    }

    setImgs() {
        this.preloaderEls.forEach((el, index) => {
            let img = el.querySelector('img');

            //we isolate the lazy loaded imgs
            if ('loading' in HTMLImageElement.prototype) {
                if (img && img.loading === 'lazy') {
                    let handleLoad = (cl) => {
                        el.classList.remove(this.params.loadingClass);
                        el.classList.add(cl);
                        setTimeout(() => {
                            el.classList.add(this.params.doneClass)
                        }, this.params.timeoutBeforeDone);
                    }
                    el.classList.add(this.params.loadingClass);
                    if (isImageOK(img)) {
                        handleLoad(this.params.loadedClass)
                    }
                    else {
                        img.addEventListener('load', () => {
                            handleLoad(this.params.loadedClass)
                        })
                        img.addEventListener('error', () => {
                            handleLoad(this.params.errorClass)
                        })
                    }
                    return;
                }
            }

            let block = {
                el: el,
                index: index,
                img: img,
                hasImg: !!img,
                loaded: false,
                success: false,
                handled: false
            };
            this.blocks.push(block)

            el.classList.add('loading')

            let imgOnLoad = (e) => {
                img.removeEventListener('load', imgOnLoad);
                img.removeEventListener('error', imgOnError);

                block.loaded = true;
                block.success = true;

                this.callForUpdate();
            }

            let imgOnError = (e) => {
                img.removeEventListener('load', imgOnLoad);
                img.removeEventListener('error', imgOnError);

                block.loaded = true;
                block.success = false;

                this.callForUpdate();
            }

            if (img) {
                if (!isImageOK(img)) {
                    img.addEventListener('load', imgOnLoad)
                    img.addEventListener('error', imgOnError)
                }
                else
                    imgOnLoad({
                        target: img
                    })
            }
        })
    }

    setGlobal() {
        window.addEventListener('load', () => {
            this.blocks.forEach((block, index) => {
                if (!block.hasImg) {
                    block.loaded = true;
                    block.success = true;
                }
            })
            this.callForUpdate();
        })
    }

    callForUpdate() {
        if (this.timeoutPending)
            return;
        this.timeoutPending = true;
        setTimeout(() => {
            this.timeoutPending = false;
            this.updateList();
        }, this.params.interval)
    }

    updateList() {
        for (let i in this.blocks) {
            let block = this.blocks[i]
            if (block.loaded) {
                if (!block.handled) {
                    block.handled = true;
                    //show block here
                    block.el.classList.remove(this.params.loadingClass);
                    if (block.success) {
                        block.el.classList.add(this.params.loadedClass);
                    }
                    else {
                        block.el.classList.add(this.params.errorClass);
                    }
                    //we queue the next one
                    this.callForUpdate();
                    return;
                }
                else {
                    continue;
                }
            }
            else {
                //we wait for the next load event
                return;
            }
        }
        //everything is loaded. We remove the spins for better performance
        setTimeout(() => {
            for (let i in this.blocks) {
                let block = this.blocks[i]
                block.el.classList.add(this.params.doneClass)
            }
        }, this.params.timeoutBeforeDone)
    }
}