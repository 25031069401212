import { tns } from "tiny-slider/src/tiny-slider";
import { waitLoad } from "../utilities/load";

const DEFAULT_PARAMS = {
    container: '',
    primaryColor: '1B326C',
    secondaryColor:'B91522',
    alternateSecondaryColor:'FF0000',
    blackColor:'000000',
    whiteColor:'FFFFFF'
}

export class FullscreenSlider {
    constructor(aparams) {
        this.params = Object.assign({},DEFAULT_PARAMS, aparams);
        waitLoad().then(()=>this.init());
    }

    
    init() {
        const SLIDER_CONFIG = {
            container: this.params.container,
            items: 1,
            controls: false,
            slideBy: "page",
            mouseDrag: false,
            autoplay: true,
            autoplayButtonOutput: false,
            swipeAngle: 30,
            autoplayTimeout: 6000,
            preventScrollOnTouch: 'auto',
            navPosition: 'bottom',
            mode: "gallery",
        }

        let sliderEl = document.querySelector(this.params.container)
        if (!sliderEl) {
            return
        }
        let slider = tns(SLIDER_CONFIG);
        this.initHomeParallax()
        this.onIndexChanged();
        slider.events.on('indexChanged', this.onIndexChanged);
        this.hideHomeSliderOnScroll(sliderEl);
    }

    onIndexChanged = (info, eventName) => {
        let slide = document.querySelector(".tns-slide-active")
        let colorSetted = slide.getAttribute("data-color")
        let scrollBlock = document.querySelector(".scroll-block")
        scrollBlock.classList.remove('scroll-block--primary');
        scrollBlock.classList.remove('scroll-block--secondary');
        scrollBlock.classList.remove('scroll-block--black');
        scrollBlock.classList.remove('scroll-block--white');

        if (colorSetted === this.params.primaryColor) {
            scrollBlock.classList.add('scroll-block--primary')
        } else if (colorSetted === this.params.secondaryColor) {
            scrollBlock.classList.add('scroll-block--secondary')
        }  else if (colorSetted === this.params.alternateSecondaryColor) {
            scrollBlock.classList.add('scroll-block--secondary')
        } else if (colorSetted === this.params.blackColor) {
            scrollBlock.classList.add('scroll-block--black')
        } else if (colorSetted === this.params.whiteColor) {
            scrollBlock.classList.add('scroll-block--white')
        }
        let btnSlideEl = slide.querySelector('.btn--slide');
        if(!btnSlideEl)
            return;

        btnSlideEl.addEventListener('mouseenter', () => {
            btnSlideEl.getElementsByTagName('span')[0].style.display = ''
            btnSlideEl.addEventListener('mouseleave', (e) => {
                e.preventDefault();
                btnSlideEl.classList.add('slideOut')
                setTimeout(() => btnSlideEl.classList.remove('slideOut'), 250)
                setTimeout(() => btnSlideEl.getElementsByTagName('span')[0].style.display = 'none', 250)
                setTimeout(() => btnSlideEl.getElementsByTagName('span')[0].style.display = '', 500)
            })
        })
    }
    initHomeParallax = () => {
        const banner = document.querySelector(this.params.container);
        document.addEventListener('scroll',  () => {
            let transXVal = this.getTranslateX(banner)
            let scrollPos = document.documentElement['scrollTop']
            banner.style.transform = 'translate3d(' + transXVal + '%,-' + (scrollPos / 2) + 'px, 0px)'
            banner.style.willChange = 'transform'
            banner.style.transition = 'transform 0.4s cubic-bezier(0,0,0,1)'
        },{passive: true});
    }
    getTranslateX = (myElement) => {
        const elStyle = myElement.getAttribute('style')
        if(elStyle && elStyle.length > 0) {
            let tmpStr = myElement.getAttribute('style').split('translate3d(');
            let subStr1 = tmpStr.splice(tmpStr.indexOf(tmpStr))
            let transStr = subStr1[0].split('%,')
            return transStr[0];
        }
    }
    hideHomeSliderOnScroll = (sliderEl) => {
        window.addEventListener('scroll',  (e) => {
            const sliderParent = sliderEl.parentElement.parentElement;
            if (window.scrollY > window.innerHeight) {
                if(!sliderParent.classList.contains('hide')){
                    sliderParent.classList.add('hide');
                }
            } else {
                if(sliderParent.classList.contains('hide')){
                    sliderParent.classList.remove('hide');
                }
            }
        });
    }
}

export class slider {
    constructor(containerClass){
        this.containerClass = containerClass;
        this.sliderHome();
    }

    
}