import { SmallBlockSlider } from '@blax-modules/base-front-end/resources/js/sliders/smallblock-slider';
import { FormValidation } from '@blax-modules/base-front-end/resources/js/forms/form-validation';

export class Career {
    constructor() {        
        let el = document.querySelector('.careermain');
        if(!el)
            return;
        new FormValidation("#frm_careers")

        new SmallBlockSlider({
            container: '.slider'
        });
    }
}

